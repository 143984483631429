import { useEffect, useState } from "react";
import { IoClose, IoHome, IoPersonCircleSharp } from "react-icons/io5";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { useAlertContext } from "../alerts/AlertContextProvider";
import { Button, Modal } from '../common';
import { useUserContext } from '../userprovider/UserProvider';

function App() {
  const location = useLocation();
  //const path = location.pathname;
  const pathAndQuery = `${location.pathname}${location.search}`;
  //const showBackLink = path.startsWith("/") && path.length > 1 && !path.startsWith("/login");
  const { user, signOut, refreshToken } = useUserContext();
  const navigate = useNavigate();

  const { alert, clearAlert } = useAlertContext()

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    if (!user && !pathAndQuery.startsWith('/login')) {
      navigate(`/login?redirect=${pathAndQuery}`)
    }
  }, [user, pathAndQuery, navigate])

  const menuButtonHandler = (action) => {
    return () => {
      if (action) action();
      setIsMenuOpen(false);
    }
  }

  return (
    <div className="bg-slate-100 text-slate-900 overflow-x-hidden">
      <Menu isOpen={isMenuOpen} onClose={menuButtonHandler()}>
        {user &&
          <div className="w-full">
            <Button onClick={menuButtonHandler(() => navigate("/settings"))}>Settings</Button>
          </div>
        }
        <div className="grow" />
        {
          user && <span>Logged in as: {user.email}</span>}
        {
          user &&
          <div className="w-full">
            <Button

              onClick={menuButtonHandler(() => {
                signOut();
                navigate("/");
              })}>
              Logout
            </Button>
          </div>
        }

        {!user &&
          <div className="w-full">
            <Button onClick={menuButtonHandler(() => navigate("/login?redirect=/"))}>Login</Button>
          </div>
        }

        {process.env.NODE_ENV === "development" && refreshToken &&
          <div className="w-full">
            <Button onClick={menuButtonHandler(refreshToken)}>Refresh Token</Button>
          </div>
        }

      </Menu >

      <div
        className="min-h-screen flex flex-col"
        style={{
          minHeight: "100SVH"
        }}
      >
        <Outlet />
        <div className="mb-14" />
      </div>
      <NavBar
        openMenu={() => setIsMenuOpen(true)}
      />
      {
        alert &&
        <Modal
          isOpen={true}
          onClose={clearAlert}
        >
          <div className="flex flex-col items-center">
            <div className="text-3xl">
              {alert.title}
            </div>
            <div className="">
              {alert.message}
            </div>
          </div>
        </Modal>
      }
    </div >
  );
};

const NavBar = ({ showBackLink, openMenu }) => {
  // const stack = useHistoryStack();
  // let backGoesHome = false;
  // if (stack.length > 1 && stack.at(-2) === "/login") {
  //   backGoesHome = true
  // }
  return (
    <nav className="fixed bottom-0 left-0 right-0 h-12 px-4 font-bold text-lg flex flex-row items-center justify-around bg-sky-800 text-slate-50">
      <Link to="/"><IoHome /></Link>
      <Link to="/list/">+</Link>
      <IoPersonCircleSharp onClick={openMenu} />
    </nav>
  );
}

// listen history change and store it
// function useHistoryStack() {
//   const [stack, setStack] = useState([]);
//   const { pathname } = useLocation();
//   const type = useNavigationType();
//   useEffect(() => {
//     if (type === "POP") {
//       setStack(stack.slice(0, stack.length - 1));
//     } else if (type === "PUSH") {
//       setStack([...stack, pathname]);
//     } else {
//       setStack([...stack.slice(0, stack.length - 1), pathname]);
//     }
//   }, [pathname, type]);

//   return stack;
// }

const Menu = ({ children, isOpen, onClose }) => {
  return (
    <main
      className={
        " fixed overflow-hidden z-10 inset-0 transform ease-in-out " +
        (isOpen
          ? " translate-x-0  "
          : " transition-all duration-500 translate-x-full  ")
      }
    >
      <section
        className={
          " w-11/12 max-w-lg right-0 absolute bg-white h-full shadow-xl duration-500 ease-in-out transition-all transform  " +
          (isOpen ? " translate-x-0 " : " translate-x-full ")
        }
      >
        <article className="relative w-100 max-w-lg pb-10 flex flex-col overflow-y-scroll h-full">
          <header className="p-4 font-bold text-lg flex flex-row items-center">
            <div>Wister</div>
            <div className="grow" />
            <IoClose onClick={onClose} />
          </header>
          <div className="px-2 flex flex-col h-full w-full gap-2 items-center">
            {children}
          </div>

        </article>
      </section>
      <div
        className=" w-screen h-full cursor-pointer "
        onClick={onClose}
      ></div>
    </main>
  );
}

export default App;
