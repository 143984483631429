export interface List {
  id: string;
  ownerId: string;
  name: string;
  public: boolean;
  inviteCode: string;
  collaborators: string[];
  type: ListType;
  items: ListItem[];
  createDate: Date;
  updateDate: Date;
}

export interface CreateListRequest {
  name: string;
  public: boolean;
  type: ListType;
  items: CreateListRequestItem[];
}

export interface CreateListRequestItem {
  description: string;
  link: string;
}

export enum ListType {
  Default = "default",
  Gift = "gift",
}

export interface ListItem {
  id: string;
  description: string;
  link: string;
  claimed: boolean;
  claimedBy: string;
  claimedByName: string;
  claimedByProfileImageUrl: string;
  createdBy: string;
  createDate: Date;
  updateDate: Date;
  version: number;
}

export interface ListSummary {
  id: string;
  name: string;
  ownerId: string;
  itemCount: number;
  lastUpdated: Date;
}

export interface UpdateListRequest {
  name: string | undefined;
  public: boolean | undefined;
}

export interface CreateItemRequest {
  description: string;
  link: string;
  claimed: boolean;
}

export interface UpdateItemRequest {
  description: string | undefined;
  link: string | undefined;
  claimed: boolean | undefined;
  current_version: number;
}

export interface SettingsModel {
  displayName: string | null;
  profileImageUrl: string | null;
}