import { Form, Formik } from 'formik';
import { createRef, useCallback, useEffect, useState } from "react";
import { IoPencil, IoShareOutline } from "react-icons/io5";
import { CreateItemRequest, ListType, UpdateItemRequest, UpdateListRequest } from '../../models';
import { Alert } from '../alerts/AlertContextProvider';
import { Button, Modal, TextInput } from '../common';
import { DisplayType } from '../common/Button';
import { AddItem } from './AddItem';
import { Item, ListItemView } from './Item';

interface IListProps {
  id: string;
  ownerId: string;
  canEdit: boolean;
  canEditItems: boolean;
  canAddItems: boolean;
  name: string;
  createDate: string;
  isPublic: boolean;
  showClaimedStatus: boolean;
  type: ListType;
  inviteLink: string;
  items: ListItemView[];
  addItem: (req: CreateItemRequest) => Promise<void>;
  updateItem: (listId: string, itemVersion: number, req: UpdateItemRequest) => Promise<void>;
  claimItem: (itemId: string, itemVersion: number) => Promise<void>;
  releaseItem: (itemId: string, itemVersion: number) => Promise<void>;
  deleteItem: (itemId: string) => Promise<void>;
  updateList: (req: UpdateListRequest) => Promise<void>;
  deleteList: () => Promise<void>;
  createAlert: (alert: Alert) => void;
}

interface ConfirmDeleteUI {
  display: boolean,
  message: string,
  callback?: () => void
}

export function List(props: IListProps): JSX.Element {
  const { name, createDate, canEdit, canEditItems, canAddItems, isPublic, inviteLink, items, type, showClaimedStatus, addItem, updateItem, claimItem, releaseItem, deleteItem, updateList, deleteList, createAlert } = props;
  const [showEditListUI, setShowEditListUI] = useState(false);
  const [showAddItemUI, setShowAddItemUI] = useState(false);
  const [showShareUI, setShowShareUI] = useState(false);
  const [confirmDeleteUI, setShowConfirmDeleteUI] = useState<ConfirmDeleteUI>({ display: false, message: "" });

  const updateListHandler = async (values) => {
    await updateList(values);
    setShowEditListUI(false);
  };

  const addItemHandler = async (values: CreateItemRequest, { resetForm }): Promise<void> => {
    await addItem(values);
    resetForm({ description: "", link: "" });
    setScrollTrigger(scrollTrigger + 1);
  };

  const updateItemHandler = async (itemId, itemVersion, values) => {
    await updateItem(itemId, itemVersion, values);
  };

  const deleteItemHandler = async (itemId: string) => {
    await deleteItem(itemId);
  };

  const shareList = async () => {
    const shareData = { title: `Join ${name}`, url: inviteLink };
    if (navigator.canShare && navigator.canShare(shareData)) {
      try {
        await navigator.share(shareData);
      }
      catch (err: any) {
        const error = err as DOMException;
        if (error.name !== "AbortError") {
          createAlert({ title: "failed to share", isError: true } as Alert);
        }
      }
    } else {
      setShowShareUI(true);
    }
  };

  const showEditUI = () => {
    setShowAddItemUI(true);
    setScrollTrigger(scrollTrigger + 1);
  }

  const lastItemRef = createRef<HTMLDivElement>();
  const scrollToBottom = useCallback(() => {
    lastItemRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [lastItemRef]);

  const [scrollTrigger, setScrollTrigger] = useState(0);
  useEffect(() => {
    if (scrollTrigger !== 0) {
      scrollToBottom();
    }

  }, [scrollTrigger, scrollToBottom]);

  return (
    <>
      {/* VIEW LIST METADATA SECTION */}
      {!showEditListUI &&
        <div className="pb-4 px-2 pt-3">
          <div className="flex flex-row items-center pb-1">
            <header className="text-3xl pr-2">{name}</header>
            {canEdit && <IoPencil onClick={() => setShowEditListUI(true)} />}
            <div className="grow" />
            <IoShareOutline onClick={shareList} className="text-lg" />
          </div>
          <div>
            <span className="text-sm">Created {createDate}</span>
          </div>
        </div>}
      {/* EDIT LIST SECTION */}
      {showEditListUI &&
        <EditList listName={name} isPublic={isPublic} onSubmit={updateListHandler} onCancel={() => setShowEditListUI(false)} />
      }
      <div className="flex flex-col gap-2">
        {items && items.map((item) => {
          return <Item
            key={item.id}
            item={item}
            listType={type}
            canEdit={canEditItems}
            showClaimedStatus={showClaimedStatus}
            claimItem={() => claimItem(item.id, item.version)}
            releaseItem={() => releaseItem(item.id, item.version)}
            updateItem={(values) => updateItemHandler(item.id, item.version, values)}
            deleteItem={() => {
              setShowConfirmDeleteUI({
                display: true,
                message: `Are you sure you want to delete "${item.description}"?`,
                callback: () => deleteItemHandler(item.id)
              });
            }}
          />
        })
        }
      </div>

      {/* ADD ITEM SECTION */}
      {showAddItemUI && <AddItem onSubmit={addItemHandler} listType={type} onCancel={() => setShowAddItemUI(false)} />}

      <div className="grow" />

      <div className="px-2 flex flex-col gap-1">
        {
          !showAddItemUI && canAddItems &&
          <Button onClick={showEditUI} displayType={DisplayType.Primary}>
            Add more items
          </Button>
        }
        {
          canEdit &&
          <Button
            className="w-full p-2 pt-0"
            onClick={() => setShowConfirmDeleteUI(
              {
                display: true,
                message: `Are you sure you want to delete "${name}"?`,
                callback: deleteList,
              }
            )}
          >
            Delete List
          </Button>
        }
      </div>

      <Modal isOpen={showShareUI} onClose={() => setShowShareUI(false)}>
        <div className="flex flex-col justify-center items-center">
          <label className="text-center">
            Click the button below to copy an invite link to the clipboard.
          </label>
          <Button onClick={() => {
            navigator.clipboard.writeText(inviteLink);
            setShowShareUI(false)
          }}>Click to copy your invite link</Button>
        </div>
      </Modal>

      <Modal isOpen={confirmDeleteUI.display} onClose={() => setShowConfirmDeleteUI({ display: false, message: "" })}>
        <div className="flex flex-col justify-center items-center">
          <label className="text-center pb-3 text-xl">
            {confirmDeleteUI.message}
          </label>
          <Button
            displayType={DisplayType.Danger}
            onClick={() => {
              if (confirmDeleteUI.callback) confirmDeleteUI.callback();
              setShowConfirmDeleteUI({ display: false, message: "" })
            }}>Delete</Button>
        </div>
      </Modal>

      <div ref={lastItemRef} />
    </>
  );
}

export default List;

interface EditListProps {
  listName: string
  isPublic: boolean
  onSubmit: (values: any) => Promise<void>
  onCancel: () => void
}

interface ValidateUpdateList {
  name: string;
}

const EditList = (props: EditListProps) => {
  const { onSubmit, onCancel, listName, isPublic } = props;

  const validateUpdateList = (values: any) => {
    const errors = {} as ValidateUpdateList;
    if (!values.name?.trim()) {
      errors.name = "A name is required";
    }
    return errors;
  };

  return (
    <Formik
      initialValues={{ name: listName, public: isPublic }}
      validate={validateUpdateList}
      onSubmit={onSubmit}>
      {({ isValid, isSubmitting, errors }) => (
        <Form>
          <TextInput
            name="name"
            placeholder="Enter a list name ..."
            error={errors.name}
            enableErrorMessage={false}
            className="text-2xl" />
          <div className="flex flex-row items-center gap-1">
            {/* <span>Public</span>
            <Toggle name="public" /> */}
            <Button onClick={onCancel} className="grow">Cancel</Button>
            <Button type="submit" displayType={DisplayType.Primary} disabled={isSubmitting || !isValid} className="grow">Update</Button>
          </div>
        </Form>
      )}
    </Formik>
  )
}


