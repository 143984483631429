import { Form, Formik } from "formik";
import { FocusEvent, useRef } from "react";
import { Link } from "react-router-dom";
import validator from 'validator';
import Button, { DisplayType } from "../common/Button";
import ErrorMessage from "../common/ErrorMessage";
import TextInput from "../common/TextInput";
import { useUserContext } from "./UserProvider";

interface ValidateRegistration {
  email: string;
  confirmEmail: string;
  password: string;
}

export const RegisterByEmailPage = () => {
  const { registerWithEmail } = useUserContext();
  return <RegisterByEmail registerWithEmail={registerWithEmail} />
}

const RegisterByEmail = ({ registerWithEmail }) => {
  // register
  const submitRegisterWithEmail = async (values, { setStatus }) => {
    try {
      await registerWithEmail(values.email, values.password);
    } catch (e: any) {
      setStatus(e.message);
    }
  };

  const validateRegister = (values) => {
    const errors = {} as ValidateRegistration;
    if (!values.email || !validator.isEmail(values.email)) {
      errors.email = "A valid email is required";
    }

    if (!values.confirmEmail
      || !validator.isEmail(values.confirmEmail)
      || values.confirmEmail !== values.email) {
      errors.confirmEmail = "Emails must match";
    }
    if (!values.password || values.password.length < 8) {
      errors.password = "A password is required (8 characters or more)";
    }
    return errors;
  }

  const cancelRef = useRef<HTMLAnchorElement>(null);

  return (
    <>
      <Formik
        initialValues={{ email: "", password: "" }}
        validate={validateRegister}
        onSubmit={submitRegisterWithEmail}
      >
        {({ status, touched, isValid, isSubmitting, errors, handleBlur }) => (
          <Form
            className="px-2 flex flex-col gap-2 h-full"
            onBlur={(e: FocusEvent<HTMLFormElement, Element>) => {
              if (!e.currentTarget.contains(cancelRef.current)) {
                handleBlur(e);
              }
            }}>
            <div>Register by entering a valid email and password</div>
            <div>
              <span>Email: </span>
              <TextInput name="email" autoFocus error={errors.email} placeholder="johnsmith@company.com" />
            </div>

            <div>
              <span>Confirm email: </span>
              <TextInput name="confirmEmail" error={errors.email} placeholder="johnsmith@company.com" />
            </div>

            <div>
              <span>Password:</span>
              <TextInput name="password" error={errors.password} password />
            </div>

            <ErrorMessage value={status} />

            <div className="grow" />

            <Button
              displayType={DisplayType.Primary}
              type="submit"
              disabled={isSubmitting || !isValid || !touched.email}
              className="w-full"
            >
              Register
            </Button>

            <Link to="/login" ref={cancelRef}>
              <Button className="w-full" >
                Cancel
              </Button>
            </Link>
          </Form>
        )}
      </Formik>
    </>
  );
}

export default RegisterByEmailPage;