import { Form, Formik } from "formik";
import { useState } from "react";
import { Link } from "react-router-dom";
import validator from 'validator';
import Button, { DisplayType } from "../common/Button";
import TextInput from "../common/TextInput";
import { useUserContext } from "./UserProvider";

interface ValidateLogin {
  email: string;
  password: string;
}

const EmailLoginPage = () => {
  const { signInWithEmail, forgotPassword } = useUserContext();
  return <EmailLogin signInWithEmail={signInWithEmail} forgotPassword={forgotPassword} />
}

const EmailLogin = ({ signInWithEmail, forgotPassword }) => {
  const [showForgotPassword, setShowForgotPassword] = useState<boolean>(false);
  const [showSentResetPasswordSuccess, setShowSentResetPasswordSuccess] = useState<boolean>(false);

  const validateLogin = (values: any) => {
    const errors = {} as ValidateLogin;
    if (!values.email || !validator.isEmail(values.email)) {
      errors.email = "A valid email is required";
    }
    if (!values.password) {
      errors.password = "A password is required";
    }
    return errors;
  };

  const validateResetPassword = (values: any) => {
    const errors = {} as ValidateLogin;
    if (!values.email || !validator.isEmail(values.email)) {
      errors.email = "A valid email is required";
    }
    return errors;
  };

  const submitSigninWithEmail = async (values, { setFieldError }) => {
    try {
      await signInWithEmail(values.email, values.password);
    } catch (error) {
      setFieldError("password", "Invalid email/password combination");
    }
  };

  const onForgotPassword = async (values, { setFieldError }) => {
    try {
      await forgotPassword(values.email)
      setShowSentResetPasswordSuccess(true);
    }
    catch (error) {
      console.log(error);
      setFieldError("email", "We don't have any record of that email")
    }
  }

  if (showForgotPassword) {
    return (
      <Formik
        initialValues={{ email: "", password: "" }}
        validate={validateResetPassword}
        onSubmit={onForgotPassword}
      >
        {({ values, isValid, isSubmitting, errors }) => (
          <Form>
            {showSentResetPasswordSuccess &&
              <div className="container mx-auto flex flex-col items-center">
                <header className="text-2xl">Password Reset Email Sent</header>
                <p>Check your spam folder if you do not see the email.</p>
                <Button
                  onClick={() => setShowSentResetPasswordSuccess(false)}>
                  Login
                </Button>
              </div>
            }
            {!showSentResetPasswordSuccess &&
              <div className="p-2">
                <label>Enter your email and click submit to have a password reset email sent to your email</label>

                <div>
                  <span>Email: </span>
                  <TextInput name="email" autoFocus error={errors.email} placeholder="johnsmith@company.com" />
                </div>

                <Button type="submit" displayType={DisplayType.Primary} disabled={isSubmitting || !isValid} className="w-full">
                  Submit
                </Button>
                <Button onClick={() => setShowForgotPassword(false)} className="w-full">
                  Cancel
                </Button>
              </div>}
          </Form>
        )}
      </Formik>
    );
  }

  return (
    <Formik
      initialValues={{ email: "", password: "" }}
      validate={validateLogin}
      onSubmit={submitSigninWithEmail}
    >
      {({ touched, isValid, isSubmitting, errors }) => (
        <Form>
          <div className="px-2 flex flex-col gap-2">
            <div>
              <span>Email: </span>
              <TextInput name="email" autoFocus error={errors.email} placeholder="johnsmith@company.com" />
            </div>

            <div>
              <span>Password:</span>
              <TextInput name="password" error={errors.password} password />
            </div>

            <Button
              type="submit"
              displayType={DisplayType.Primary}
              disabled={isSubmitting || !isValid || !touched.email}
              className="w-full pt-3"
            >
              Sign in
            </Button>
            <Button onClick={() => setShowForgotPassword(true)} className="w-full">
              Forgot Password?
            </Button>
            <Link to="/login/">
              <Button className="w-full">Cancel</Button>
            </Link>

          </div>

        </Form>
      )}
    </Formik>
  );
}

export default EmailLoginPage;