import { Form, Formik } from "formik";
import { CreateItemRequest, ListType } from "../../models";
import Button, { DisplayType } from "../common/Button";
import TextInput from "../common/TextInput";
import { validateItem } from "./Item";

export interface AddItemProps {
  onSubmit: (values: CreateItemRequest, { resetForm }) => Promise<void>
  onCancel: () => void
  listType: ListType
}

export const AddItem = (props: AddItemProps) => {
  const { onSubmit, listType, onCancel } = props;
  return (
    <Formik
      initialValues={{ description: "", link: "", claimed: false }}
      validate={validateItem}
      onSubmit={onSubmit}>
      {({ isValid, isSubmitting }) => (
        <Form className="border rounded px-3 py-2 my-2 bg-white flex flex-col gap-1">
          <span>Add {listType === ListType.Gift ? "a gift" : "an item"}:</span>
          <TextInput name="description" placeholder="item description" />
          <TextInput name="link" placeholder="https://amazon.com/... (optional)" />
          <div className="flex flex-row gap-1">
            <Button className="grow" onClick={onCancel}>Cancel</Button>
            <Button className="grow" displayType={DisplayType.Primary} type="submit" disabled={isSubmitting || !isValid}>Add Item</Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}