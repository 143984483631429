import { Form, Formik } from "formik";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAPIContext } from "../apiprovider/APIProvider";
import Button, { DisplayType } from "../common/Button";
import TextInput from "../common/TextInput";
import { useUserContext } from "../userprovider/UserProvider";

export const getDisplayNameRedirectPath = (redirect: string) => {
  return `/settings/displayname?redirect=${redirect}`
}

export const EditDisplayNamePage = () => {

  const { setDisplayName } = useAPIContext();
  const { settings, setUserSettings } = useUserContext();

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const redirectUrlFromQuery = searchParams.get("redirect");

  const setDisplayNameHandler = async (displayName: string) => {
    const settings = await setDisplayName(displayName);
    setUserSettings(settings);
    if (redirectUrlFromQuery) {
      navigate(redirectUrlFromQuery);
      return;
    }
    navigate("/settings")
  }

  return (
    <EditDisplayName
      newUser={redirectUrlFromQuery ? true : false}
      displayName={settings.displayName}
      setDisplayName={setDisplayNameHandler} />
  );
}

export const EditDisplayName = ({ displayName, setDisplayName, newUser }) => {
  const validate = (values: any) => {
    if (!values.displayName) {
      return {
        displayName: "Name cannot be empty"
      }
    }
  }
  const submit = async (values: any) => {
    await setDisplayName(values.displayName);
  }

  return (
    <Formik
      initialValues={{ displayName: displayName }}
      validate={validate}
      onSubmit={submit} >
      {({ isValid, isSubmitting, errors }) => (
        <Form
          className="px-2 flex flex-col grow"
        >
          {
            newUser &&
            <>
              <div className="text-2xl text-center">Welcome!</div>
              <div className="text-lg pb-6">
                It looks like you haven't set your nickname yet. This will help people know who has claimed an item from your list.
                You can always update your nickname later in the settings page.
              </div>
            </>
          }

          <span>
            Nickname:
          </span>

          <TextInput name="displayName" placeholder="Tap here to set" />

          <p className="text-xs text-slate-600 text-center pt-2">
            Your display name is the name that other users will see when you claim an item.
          </p>

          <span className="grow" />

          <Button
            type="submit"
            displayType={DisplayType.Primary}
            disabled={!isValid || isSubmitting}
          >
            Save
          </Button>
        </Form>
      )}
    </Formik >

  );
}
