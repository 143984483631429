import { Form, Formik } from "formik";
import { useNavigate } from "react-router-dom";
import { isHttpsUri, isHttpUri } from "valid-url";
import { useAPIContext } from "../apiprovider/APIProvider";
import Button, { DisplayType } from "../common/Button";
import TextInput from "../common/TextInput";
import { useUserContext } from "../userprovider/UserProvider";

export const EditProfileImageUrlPage = () => {
  const { setProfileImageUrl } = useAPIContext();
  const { settings, setUserSettings } = useUserContext();

  const setProfileImageUrlHandler = async (imageUrl: string) => {
    const settings = await setProfileImageUrl(imageUrl);
    setUserSettings(settings);
  }
  return (
    <EditProfileImageUrl profileImageUrl={settings.profileImageUrl} setProfileImageUrl={setProfileImageUrlHandler} />
  );
}

export const EditProfileImageUrl = ({ profileImageUrl, setProfileImageUrl }) => {
  const navigate = useNavigate();
  const validate = (values: any) => {
    if (!values.imageUrl) {
      return {
        imageUrl: "Cannot be empty"
      }
    } else {
      const validhttps = isHttpsUri(values.imageUrl);
      const validhttp = isHttpUri(values.imageUrl);
      if (!(validhttps || validhttp)) {
        return {
          imageUrl: "links must be a valid url (https://...)"
        }
      }
      if (!(values.imageUrl.endsWith(".png")
        || values.imageUrl.endsWith(".jpg")
        || values.imageUrl.endsWith(".jpeg")
        || values.imageUrl.endsWith(".webp")
        || values.imageUrl.endsWith(".avif"))) {
        return {
          imageUrl: "images must be a png, jpg, jpeg format"
        }
      }
    }
  }
  const submit = async (values: any) => {
    await setProfileImageUrl(values.imageUrl);
    navigate('/settings');
  }
  return (
    <Formik
      initialValues={{ imageUrl: profileImageUrl }}
      validate={validate}
      onSubmit={submit} >
      {({ isValid, isSubmitting, errors }) => (
        <Form
          className="px-2 flex flex-col grow"
        >
          <span>
            Profile Image:
          </span>

          <TextInput name="imageUrl" />

          <p className="text-xs text-slate-600 text-center pt-2">
            This image will show as an icon near items you have claimed. Find an picture you want to use and copy the url and paste it here.
          </p>

          <span className="grow" />

          <Button
            type="submit"
            displayType={DisplayType.Primary}
            disabled={!isValid || isSubmitting}
          >
            Save
          </Button>
        </Form>
      )}
    </Formik >
  );
}