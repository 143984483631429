import { IoClose } from "react-icons/io5";

interface IModalProps {
  isOpen: boolean
  onClose: () => void
  children: any
}
export const Modal = (props: IModalProps) => {
  const { isOpen, onClose, children } = props;
  return (
    <main
      onClick={onClose}
      className={
        "flex flex-col items-center fixed overflow-hidden z-10 bg-slate-900 bg-opacity-25 inset-0 " +
        (isOpen
          ? "transition-opacity opacity-100 duration-100 z-10"
          : " transition-opactiy duration-100 opacity-0 -z-10")
      }>
      <div className="2xl:container w-full 2xl:mx-auto py-48 px-4 md:px-28 flex justify-center items-center">
        <div
          onClick={(e) => e.stopPropagation()}
          className="w-11/12 rounded md:w-auto dark:bg-slate-800 relative flex flex-col justify-center items-center bg-white pt-8 pb-4 px-4 md:px-24 xl:py-24 xl:px-36">
          <IoClose onClick={onClose} className="absolute right-3 top-3" />
          {children}
        </div>
      </div>
    </main>
  );
}

export default Modal;