import { IoChevronBack } from "react-icons/io5";
import { Link, Outlet, useLocation } from "react-router-dom";


const SettingsPage = () => {
  const location = useLocation();

  const showBack = location.pathname !== "/settings" && location.pathname !== "/settings/";
  return (
    <div className="min-h-full flex flex-col gap-2 pt-6">
      {showBack &&
        <Link to="/settings" className="fixed pl-6 pt-8 left-0 top-0 text-xl">
          <IoChevronBack />
        </Link>}
      <header className="text-2xl text-center">
        Settings
      </header>

      <Outlet />
    </div>
  );
}


export default SettingsPage;
