import { CaptureConsole } from '@sentry/integrations';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import AlertContextProvider from './components/alerts';
import APIContextProvider from './components/apiprovider/APIProvider';
import App from './components/app';
import FourOhFour from './components/fourohfour';
import Home from './components/home';
import ListPage, { CreateList } from './components/list';
import { EditDisplayNamePage } from './components/settings/EditDisplayName';
import { EditProfileImageUrlPage } from './components/settings/EditProfileImageUrl';
import Settings from './components/settings/Settings';
import SettingsPage from './components/settings/SettingsPage';
import EmailLoginPage from './components/userprovider/EmailLogin';
import { DefaultLoginPage, Login } from './components/userprovider/Login';
import RegisterByEmailPage from './components/userprovider/RegisterByEmail';
import UserContextProvider from './components/userprovider/UserProvider';
import './index.css';
import reportWebVitals from './reportWebVitals';

if (process.env.NODE_ENV !== "development") {
  Sentry.init({
    dsn: "https://68b8662dee26460786a60ad00878dd11@o4504285714710528.ingest.sentry.io/4504285717856256",
    integrations: [
      new BrowserTracing(),
      new CaptureConsole({
        levels: ['error']
      })
    ],

    tracesSampleRate: 1.0,
  });
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <AlertContextProvider>
    <BrowserRouter>
      <UserContextProvider>
        {(token: string, refreshToken: () => Promise<string>) => {
          return (
            <APIContextProvider token={token} refreshToken={refreshToken}>
              <Routes>
                <Route path="/" element={<App />}>
                  <Route path="list/" element={<CreateList />} />
                  <Route path="list/:listId" element={<ListPage />} />
                  <Route path="login" element={<Login />}>
                    <Route path="email" element={<EmailLoginPage />} />
                    <Route path="register" element={<RegisterByEmailPage />} />
                    <Route path="" element={<DefaultLoginPage />} />
                  </Route>
                  <Route path="settings" element={<SettingsPage />}>
                    <Route path="displayname" element={<EditDisplayNamePage />} />
                    <Route path="profileimage" element={<EditProfileImageUrlPage />} />
                    <Route path="" element={<Settings />} />
                  </Route>
                  <Route path="" element={<Home />} />
                  <Route path="*" element={<FourOhFour />} />
                </Route>
              </Routes>
            </APIContextProvider>
          )
        }}
      </UserContextProvider>
    </BrowserRouter>

  </AlertContextProvider>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
