import * as Sentry from "@sentry/react";
import axios, { AxiosResponse } from 'axios';
import { createContext, useContext, useMemo } from 'react';
import { CreateItemRequest, CreateListRequest, List, ListItem, ListSummary, SettingsModel, UpdateItemRequest, UpdateListRequest } from '../../models';
import { useUserContext } from '../userprovider/UserProvider';

export interface IAPIContext {
  baseUrl: string;
  getLists: () => Promise<ListSummary[]>,
  getList: (id: string) => Promise<List>,
  createList: (list: CreateListRequest) => Promise<List>,
  updateList: (listId: string, req: UpdateListRequest) => Promise<List>,
  deleteList: (listId: string) => Promise<void>,
  addItem: (listId: string, req: CreateItemRequest) => Promise<List>,
  updateItem: (listId: string, itemId: string, req: UpdateItemRequest) => Promise<List>,
  deleteItem: (listId: string, itemId: string) => Promise<List>,
  addCollaborator: (listId: string, inviteCode: string) => Promise<List>,
  getSettings: () => Promise<SettingsModel>,
  setDisplayName: (displayName: string) => Promise<SettingsModel>,
  setProfileImageUrl: (imageUrl: string) => Promise<SettingsModel>
}

export const APIContext = createContext<IAPIContext | undefined>(undefined);

export function useAPIContext() {
  const context = useContext(APIContext);
  if (context === undefined) {
    throw new Error("APIContext must be used inside an APIProvider")
  }

  return context
}

interface APIContextProviderProps {
  children: any
  token: string
  refreshToken: () => Promise<string>
}

const APIContextProvider = (props: APIContextProviderProps) => {
  const { children, token, refreshToken } = props;

  const { setUserSettings } = useUserContext();

  if (token) {
    axios.defaults.headers.common = { 'Authorization': `bearer ${token}` }
  }

  const getLists = async () => {
    const result = await get("/api/v1/list");
    if (!result) {
      return;
    }

    const lists = result.data.map((listSummary: { id: any; name: any; item_count: any; update_date: string }) => {
      return {
        id: listSummary.id,
        name: listSummary.name,
        itemCount: listSummary.item_count ?? 0,
        lastUpdated: new Date(listSummary.update_date)
      }
    });

    lists.sort((a: ListSummary, b: ListSummary) => {
      return b.lastUpdated.getTime() - a.lastUpdated.getTime();
    });

    return lists;
  };

  const mapList = (rawList: any): List | undefined => {
    if (!rawList) {
      return;
    }
    let items: ListItem[] = [];
    if (rawList.items) {
      items = rawList.items.map(item => {
        return {
          id: item.id,
          description: item.description,
          link: item.link,
          claimed: item.claimed,
          claimedBy: item.claimed_by,
          claimedByName: item.claimed_by_name,
          claimedByProfileImageUrl: item.claimed_by_profile_image_url,
          createdBy: item.created_by,
          createDate: new Date(item.create_date),
          updateDate: new Date(item.update_date),
          version: item.version
        };
      });
    }

    const list = {
      id: rawList.id,
      ownerId: rawList.owner_id,
      name: rawList.name,
      public: rawList.public,
      inviteCode: rawList.invite_code,
      collaborators: rawList.colaborators,
      type: rawList.type,
      items: items,
      createDate: new Date(rawList.create_date),
      updateDate: new Date(rawList.update_date),
    };
    return list;
  }

  const getList = async (listID: string): Promise<List | undefined> => {
    const response = await get(`/api/v1/list/${listID}`, "/api/v1/list/{listID}");
    return mapList(response?.data);
  }

  const createList = async (list: CreateListRequest): Promise<List | undefined> => {
    const response = await post("/api/v1/list", list);
    return mapList(response?.data);
  }

  const updateList = async (listId: string, updateListReq: UpdateListRequest): Promise<List | undefined> => {
    const response = await put(`/api/v1/list/${listId}`, updateListReq, "/api/v1/list");
    return mapList(response?.data);
  }

  const deleteList = async (listId: string): Promise<void> => {
    await deleteReq(`/api/v1/list/${listId}`, null, "/api/v1/list");
  }

  const addItem = async (listId: string, item: CreateItemRequest): Promise<List | undefined> => {
    const response = await post(`/api/v1/list/${listId}/item`, item, "/api/v1/list/{LIST_ID}/item");
    return mapList(response?.data);
  }

  const updateItem = async (listId: string, itemId: string, updateItemReq: UpdateItemRequest): Promise<List | undefined> => {
    const response = await put(`/api/v1/list/${listId}/item/${itemId}`, updateItemReq, "/api/v1/list/{LIST_ID}/item/{ITEM_ID}");
    return mapList(response?.data);
  }

  const deleteItem = async (listId: string, itemId: string): Promise<List | undefined> => {
    const response = await deleteReq(`/api/v1/list/${listId}/item/${itemId}`, null, "/api/v1/list/{LIST_ID/item/{ITEM_ID}");
    return mapList(response?.data);
  }

  const addCollaborator = async (listId: string, inviteCode: string): Promise<List | undefined> => {
    const req = {
      invite_code: inviteCode
    }
    const response = await post(`/api/v1/list/${listId}/collaborator`, req);
    return mapList(response?.data);
  }

  const getSettings = async (): Promise<SettingsModel> => {
    const response = await get(`/api/v1/settings`);
    return mapSettingsResult(response);
  }

  const setDisplayName = async (displayName: string): Promise<SettingsModel> => {
    const payload = {
      display_name: displayName
    }
    const response = await put(`/api/v1/settings/displayname`, payload);
    return mapSettingsResult(response);
  }

  const setProfileImageUrl = async (imageUrl: string): Promise<SettingsModel> => {
    const payload = {
      profile_image_url: imageUrl
    }
    const response = await put(`/api/v1/settings/profileimage`, payload);
    return mapSettingsResult(response);
  }

  const mapSettingsResult = (response: AxiosResponse | undefined): SettingsModel => {
    return {
      displayName: response?.data?.display_name,
      profileImageUrl: response?.data?.profile_image_url
    };
  }

  const deleteReq = async (url: string, payload: any, cleanUrl: string = url): Promise<AxiosResponse<any, any> | undefined> => {
    return await makeRequest(axios.delete, url, payload, `api-delete-${cleanUrl}`);
  }

  const put = async (url: string, payload: any, cleanUrl: string = url): Promise<AxiosResponse<any, any> | undefined> => {
    return await makeRequest(axios.put, url, payload, `api-put-${cleanUrl}`);
  }

  const post = async (url: string, payload: any, cleanUrl: string = url): Promise<AxiosResponse<any, any> | undefined> => {
    return await makeRequest(axios.post, url, payload, `api-post-${cleanUrl}`);
  }

  const get = async (url: string, cleanUrl: string = url): Promise<AxiosResponse<any, any> | undefined> => {
    return await makeRequest(axios.get, url, null, `api-get-${cleanUrl}`);
  }

  const makeRequest = async (axiosFunc: (url: string, payload: any) => Promise<AxiosResponse<any, any>>, url: string, payload: any, opName: string): Promise<AxiosResponse<any, any> | undefined> => {
    const transaction = Sentry.startTransaction({ name: opName });
    const span = transaction.startChild({ op: opName }); // This function returns a Span
    try {
      const response = await axiosFunc(url, payload);
      return response;
    } catch (error: any) {
      if (error.response.status === 401) {
        console.log("retrying request due to old jwt token");
        const newToken = await refreshToken();
        axios.defaults.headers.common = { 'Authorization': `bearer ${newToken}` }
        console.log("retrying");
        const response = axiosFunc(url, payload);
        return response;
      }
      console.error(error);
      throw error;
    } finally {
      span.finish(); // Remember that only finished spans will be sent with the transaction
      transaction.finish(); // Finishing the transaction will send it to Sentry
    }
  }

  useMemo(() => {
    if (token) {
      getSettings().then((settings) => {
        setUserSettings(settings);
      })
    }
  }, [token])

  return (
    <APIContext.Provider
      value={{
        baseUrl: `${window.location.protocol}//${window.location.host}`,
        getLists: getLists,
        getList: getList,
        createList: createList,
        updateList: updateList,
        deleteList: deleteList,
        addItem: addItem,
        updateItem: updateItem,
        deleteItem: deleteItem,
        addCollaborator: addCollaborator,
        getSettings: getSettings,
        setDisplayName: setDisplayName,
        setProfileImageUrl: setProfileImageUrl
      } as IAPIContext}
    >
      {children}
    </APIContext.Provider>
  )
};

export default APIContextProvider;