import { Field } from 'formik';

interface IToggleProps {
  name: string
}

export const Toggle = (props: IToggleProps) => {
  const { name } = props;

  return (
    <div className="p-1">
      <label htmlFor={`default-toggle-${name}`} className="inline-flex relative items-center cursor-pointer">
        <Field name={name} type="checkbox" id={`default-toggle-${name}`} className="sr-only peer" />
        <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-sky-300 dark:peer-focus:ring-sky-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-sky-600"></div>
      </label>
    </div>
  );
}

export default Toggle;