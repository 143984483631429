import 'firebaseui/dist/firebaseui.css';

//import { facebookAuthProvider, googleAuthProvider, signIn } from './firebase';


export const StyledFirebaseAuth = ({ signInWithGoogle, signInWithFacebook }) => {
  return (
    <div className="firebaseui-container firebaseui-page-provider-sign-in firebaseui-id-page-provider-sign-in firebaseui-use-spinner">
      <div className="firebaseui-card-content">

        <ul className="firebaseui-idp-list">
          <li className="firebaseui-list-item">
            <button
              onClick={signInWithGoogle}
              className="firebaseui-idp-button mdl-button mdl-js-button mdl-button--raised firebaseui-idp-google firebaseui-id-idp-button" data-provider-id="google.com" style={{ backgroundColor: "#ffffff" }} data-upgraded=",MaterialButton">
              <span className="firebaseui-idp-icon-wrapper">
                <img className="firebaseui-idp-icon" alt="" src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg" />
              </span>
              <span className="firebaseui-idp-text firebaseui-idp-text-long">Sign in with Google</span>
              <span className="firebaseui-idp-text firebaseui-idp-text-short">Google</span>
            </button>
          </li>
          <li className="firebaseui-list-item">
            <button
              onClick={signInWithFacebook}
              className="firebaseui-idp-button mdl-button mdl-js-button mdl-button--raised firebaseui-idp-facebook firebaseui-id-idp-button" data-provider-id="facebook.com" style={{ backgroundColor: "#3b5998" }} data-upgraded=",MaterialButton">
              <span className="firebaseui-idp-icon-wrapper">
                <img className="firebaseui-idp-icon" alt="" src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/facebook.svg" />
              </span>
              <span className="firebaseui-idp-text firebaseui-idp-text-long">Sign in with Facebook</span>
              <span className="firebaseui-idp-text firebaseui-idp-text-short">Facebook</span>
            </button>
          </li>
        </ul>
      </div>
      <div className="firebaseui-card-footer firebaseui-provider-sign-in-footer"></div>
    </div>
  );
}

export default StyledFirebaseAuth;