import { ReactNode, useCallback, useState } from "react";
import { IoPencil, IoTrash } from "react-icons/io5";
import { isHttpsUri, isHttpUri } from "valid-url";
import { ListType } from "../../models";
import { Button, Card, RowActions } from "../common";
import { DisplayType } from "../common/Button";
import { CardDisplayType } from "../common/Card";
import { ProfileIcon } from "../common/ProfileIcon";
import { EditItem } from "./EditItem";

export interface ListItemView {
  id: string;
  description: string;
  link: string;
  claimed: boolean;
  claimedByCurrentUser: boolean;
  claimedByName: string;
  claimedByProfileImageUrl: string;
  canEdit: boolean;
  createDate: Date;
  updateDate: Date;
  version: number;
}

export interface ValidateItem {
  description: string;
  link: string
}

export const validateItem = (values: any) => {
  const errors = {} as ValidateItem;
  if (!values.description?.trim()) {
    errors.description = "A description is required";
  }

  const linkTrimmed = values.link?.trim();
  if (linkTrimmed) {
    const validhttps = isHttpsUri(linkTrimmed);
    const validhttp = isHttpUri(linkTrimmed);
    if (!(validhttps || validhttp)) {
      errors.link = "links must be a valid url (https://...)";
    }
  }
  return errors;
};

export interface ItemProps {
  item: ListItemView,
  canEdit: boolean,
  showClaimedStatus: boolean,
  listType: ListType,

  releaseItem: () => void,
  claimItem: () => void,
  updateItem: (values: any) => Promise<void>,
  deleteItem: () => void
}

export const Item = (props: ItemProps) => {
  const { item, listType, canEdit, showClaimedStatus, releaseItem, claimItem, updateItem, deleteItem } = props;

  const [showEditItemUI, setShowEditItemUI] = useState(false);

  const updateItemHandler = useCallback(async (values: any) => {
    await updateItem(values)
    setShowEditItemUI(false);
  }, [updateItem, setShowEditItemUI])

  let claimElement: ReactNode = null;
  let cardDisplayType = CardDisplayType.Default;
  if (showClaimedStatus) {
    if (item.claimedByCurrentUser) {
      claimElement = <Button style={{ maxWidth: "5rem" }} onClick={releaseItem}>un-claim</Button>;
      cardDisplayType = CardDisplayType.Primary;
    } else if (item.claimed) {
      cardDisplayType = CardDisplayType.Disabled;
      claimElement = <div className="text-center">Someone claimed this</div>;
      if (listType === ListType.Default && (item.claimedByName || item.claimedByProfileImageUrl)) {
        let imgTag = item.claimedByProfileImageUrl ? <ProfileIcon imageUrl={item.claimedByProfileImageUrl} /> : ""
        claimElement = <div className="flex flex-row items-center gap-1"><span>{item.claimedByName}</span>{imgTag}</div>
        //`${item.claimedByName}`
      }
    } else {
      claimElement = <Button displayType={DisplayType.Primary} onClick={claimItem} style={{ maxWidth: "4rem" }}>Claim!</Button>;
    }
  }

  let linkText = item.link;

  let itemCard = (
    <Card key={item.id} displayType={cardDisplayType}>
      <div className={"flex flex-row items-center"}>

        {/* EDIT ITEM SECTION */}
        {showEditItemUI &&
          <div className="w-full">
            <EditItem
              item={item}
              onSubmit={updateItemHandler}
              onCancel={() => setShowEditItemUI(false)}
            />
          </div>
        }

        {/* VIEW ITEM SECTION */}
        {!showEditItemUI &&
          <>
            <div>
              <div className="flex flex-row w-full">
                <div className="text-lg">{item.description}</div>
              </div>
              {item.link &&
                <a
                  style={{
                    display: "block",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                  }}
                  className="text-xs underline font-bold max-w-[225px] sm:max-w-lg md:max-w-xl"
                  href={item.link}
                  target="_blank"
                  rel="noopener noreferrer">
                  {linkText}
                </a>}
            </div>
            <div className="grow" />
            {claimElement}
          </>
        }
      </div>
    </Card>
  );

  // don't enable row actions if you can't edit or are already editing
  if (!canEdit || showEditItemUI || (listType !== ListType.Gift && item.claimed && !item.claimedByCurrentUser)) {
    return itemCard;
  }

  return (
    <RowActions
      firstAction={
        <div
          onClick={deleteItem}
          className="bg-rose-600 shadow-md text-white h-full flex flex-row items-center pl-6">
          <IoTrash className="text-2xl" />
        </div>
      }
      secondAction={
        <div
          onClick={() => setShowEditItemUI(true)}
          className="bg-sky-700 shadow-md text-white h-full flex flex-row items-center pl-6">
          <IoPencil className="text-2xl" />
        </div>
      }
    >
      {itemCard}
    </RowActions>
  );
}