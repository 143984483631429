interface ProfileImageProps {
  imageUrl: string
}

export const ProfileIcon = (props: ProfileImageProps) => {
  const { imageUrl } = props;

  return (
    <div>
      <img
        src={imageUrl}
        alt="profile icon"
        className="object-cover h-10 w-10 rounded-full" />
    </div>
  )
}
