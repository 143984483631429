import * as Sentry from "@sentry/react";
import { initializeApp } from "firebase/app";
import {
  AdditionalUserInfo, AuthErrorCodes, browserLocalPersistence,
  browserPopupRedirectResolver,
  createUserWithEmailAndPassword,
  debugErrorMap,
  EmailAuthProvider,
  FacebookAuthProvider,
  fetchSignInMethodsForEmail,
  getAdditionalUserInfo, getIdToken, getRedirectResult, GoogleAuthProvider, indexedDBLocalPersistence,
  initializeAuth,
  inMemoryPersistence, onAuthStateChanged, sendPasswordResetEmail,
  signInWithEmailAndPassword, signInWithRedirect,
  signOut as firebaseSignOut,
  User,
  UserCredential
} from "firebase/auth";
import { createContext, Reducer, useContext, useEffect, useReducer, useRef } from 'react';
import { SettingsModel } from "../../models";

const UserContext = createContext<IUserContext | undefined>(undefined);

const firebaseConfig: any = {};
firebaseConfig.apiKey = process.env.REACT_APP_LISTER_AUTH_API_KEY;
firebaseConfig.authDomain = process.env.REACT_APP_LISTER_AUTH_DOMAIN;
firebaseConfig.projectId = "lister-349905";
firebaseConfig.storageBucket = "lister-349905.appspot.com";
firebaseConfig.messagingSenderId = "1556099540";
firebaseConfig.appId = "1:1556099540:web:80775d03e48543db19e641";

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
const auth = initializeAuth(app, {
  popupRedirectResolver: browserPopupRedirectResolver,
  persistence: [indexedDBLocalPersistence, browserLocalPersistence, inMemoryPersistence],
  errorMap: debugErrorMap
});

export const googleAuthProvider = new GoogleAuthProvider();
export const facebookAuthProvider = new FacebookAuthProvider();
export const emailAuthProvider = new EmailAuthProvider();

interface IUserContext {
  loggedIn: Boolean;
  loading: Boolean;
  token: string | null;
  user: User | null;
  additionalInfo: AdditionalUserInfo | null;
  settings: SettingsModel;
  signInWithGoogle: () => void;
  signInWithFacebook: () => void;
  signInWithEmail: (email: string, password: string) => Promise<void>;
  registerWithEmail: (email: string, password: string) => Promise<void>;
  refreshToken: () => Promise<string>;
  signOut: () => void;
  forgotPassword: (email: string) => Promise<void>;
  setUserSettings: (settings: SettingsModel) => void;
}

export function useUserContext() {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error("UserContext must be used inside a UserProvider")
  }

  return context
}

const initialState = {
  loggedIn: false,
  loading: false,
  user: null,
  additionalInfo: null,
  token: null,
  settings: {} as SettingsModel,
  signInWithGoogle: () => { },
  signInWithFacebook: () => { },
  signInWithEmail: async (email: string, password: string) => { },
  registerWithEmail: async (email: string, password: string): Promise<void> => { },
  signOut: () => Promise.resolve(),
  refreshToken: () => Promise.resolve(""),
  forgotPassword: async (email: string) => {
    return await sendPasswordResetEmail(auth, email)
  },
  setUserSettings: (settings: SettingsModel) => { }
} as IUserContext;

function reducer(state: IUserContext, action: any) {
  switch (action.type) {
    case 'LOGIN_INITIATED':
      console.log("login initiated", action.data);
      return { ...state, loading: true };
    case 'LOGIN_SUCCESS':
      console.log("login success", action.data);
      Sentry.setContext("user", {
        uid: action?.data?.user?.uid
      });
      return { ...state, user: action.data.user, loading: false, loggedIn: true, token: action.data.token, refreshToken: action.data.refreshToken };
    case 'SET_ADDITIONAL_USER_INFO':
      console.log("additional user info", action.data);
      return { ...state, additionalInfo: action.data.additionalData };
    case 'LOGOUT':
      console.log("user logged out", action.data);
      return { ...state, user: null, loggedIn: false, loading: false, refreshToken: undefined };
    case 'TOKEN_REFRESHED':
      console.log("user jwt token refreshed: ", action.data);
      return { ...state, token: action.data }
    case 'SET_USER_SETTINGS':
      console.log("user settings: ", action.data);
      return { ...state, settings: action.data }
    case 'ERROR':
      console.error("auth error:", action.data)
      return { ...state, user: null, error: action.data, loggedIn: false, loading: false, token: "" };
    default:
      console.error("unexpected user action", action)
      throw new Error();
  }
}

const UserContextProvider = (props) => {
  const { children } = props;
  const [userState, dispatch] = useReducer<Reducer<IUserContext, any>>(reducer, initialState);
  const userStateRef = useRef(userState);
  userStateRef.current = userState;

  const onLoginSuccess = (user: User, token: string, refreshToken: () => Promise<string>) => {
    const refreshAndSetToken = async () => {
      const newToken = await refreshToken();
      dispatch({
        type: 'TOKEN_REFRESHED',
        data: newToken
      });
      return newToken;
    }
    dispatch({
      type: 'LOGIN_SUCCESS', data: {
        user: user,
        token: token,
        refreshToken: refreshAndSetToken
      }
    })
  }

  const onLogoutSuccess = () => dispatch({ type: 'LOGOUT' });

  const onError = (error: any) => dispatch({ type: 'ERROR', data: error });

  const signInWithEmail = async (email: string, password: string) => {
    try {
      const result = await signInWithEmailAndPassword(auth, email, password)
      handleUserCredential(result);
    } catch (e: any) {
      if (e.code === AuthErrorCodes.USER_DELETED || e.code === AuthErrorCodes.INVALID_PASSWORD) {
        throw e;
      }
      onError(e);
    }
  }

  const signInWithGoogle = () => {
    signInWithRedirect(auth, googleAuthProvider);
  }

  const signInWithFacebook = () => {
    signInWithRedirect(auth, facebookAuthProvider);
  }

  const registerWithEmail = async (email: string, password: string) => {
    try {
      const credential = await createUserWithEmailAndPassword(auth, email, password)
      await handleUserCredential(credential);
    } catch (e: any) {
      onError(e);
      if (e.code === AuthErrorCodes.EMAIL_EXISTS) {
        let signinMethods = await fetchSignInMethodsForEmail(auth, email);
        if (signinMethods.length > 0) {
          switch (signinMethods[0]) {
            case EmailAuthProvider.PROVIDER_ID:
              throw new Error("This email is already in use. Try using \"Forgot Password\"");
            case GoogleAuthProvider.GOOGLE_SIGN_IN_METHOD:
              throw new Error("This email is registered with a Google account. Try logging in with the Google option.");
            case FacebookAuthProvider.FACEBOOK_SIGN_IN_METHOD:
              throw new Error("This email is registered with a Facebook account. Try logging in with the Facebook option.");
          }
        }
      }
      console.log(e);
      throw new Error("Hmm. Something went wrong. We'll look into that. Try using a different email/password combination.")
    }
  }

  const handleUserCredential = async (result: UserCredential) => {
    if (result.user) {
      const additionalInfo = getAdditionalUserInfo(result)
      dispatch({
        type: 'SET_ADDITIONAL_USER_INFO', data: {
          additionalInfo: additionalInfo,
        }
      });
    }
  }

  const signOut = async () => {
    await firebaseSignOut(auth);
    onLogoutSuccess();
  };

  const setUserSettings = (settings: SettingsModel) => {
    dispatch({
      type: 'SET_USER_SETTINGS',
      data: settings
    })
  }

  useEffect(() => {
    dispatch({ type: 'LOGIN_INITIATED' });
    const handleRedirectResult = async () => {
      const result = await getRedirectResult(auth);
      if (result) {
        handleUserCredential(result)
      }
    }

    onAuthStateChanged(auth, async (user: User | null) => {
      if (user) {
        // set the token now
        const token = await getIdToken(user);
        const refreshToken = async () => {
          return await getIdToken(user, true);
        }
        onLoginSuccess(user, token, refreshToken);
      } else {
        onLogoutSuccess();
      }
    }, (error) => {
      console.error("failed to log in:")
      console.error(error)
      onError(error)
    });

    handleRedirectResult();
  }, [])

  return (
    <UserContext.Provider
      value={{
        user: userState.user,
        additionalInfo: userState.additionalInfo,
        loggedIn: userState.loggedIn,
        loading: userState.loading,
        token: userState.token,
        settings: userState.settings,
        signInWithGoogle: signInWithGoogle,
        signInWithFacebook: signInWithFacebook,
        signInWithEmail: signInWithEmail,
        registerWithEmail: registerWithEmail,
        refreshToken: userState.refreshToken,
        signOut: signOut,
        forgotPassword: userState.forgotPassword,
        setUserSettings: setUserSettings
      }}
    >
      {children(userState.token, userState.refreshToken)}
    </UserContext.Provider>
  )
};

export default UserContextProvider;