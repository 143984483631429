import { Link } from "react-router-dom";

export enum CardDisplayType {
  Default,
  Primary,
  Disabled
}

interface LinkCardProps {
  children: any
  to: string
  displayType: CardDisplayType
}

export const LinkCard = (props: LinkCardProps) => {
  const { children, to, displayType } = props;

  return (
    <Link
      to={displayType === CardDisplayType.Disabled ? "#" : to}
      className={`py-3 px-4 border shadow-md  flex flex-col  ${getDisplayTypeClass(displayType)}`}
    >
      {children}
    </Link>
  )
}

interface CardProps {
  children: any
  displayType: CardDisplayType
}


export const Card = (props: CardProps) => {
  const { children, displayType } = props;

  return (
    <div
      className={`py-3 px-2 border shadow-md flex flex-col ${getDisplayTypeClass(displayType)}`}
    >
      {children}
    </div>
  )
}

const getDisplayTypeClass = (type: CardDisplayType): string => {
  switch (type) {
    case CardDisplayType.Disabled:
      return " bg-white opacity-50 ";
    case CardDisplayType.Primary:
      return " bg-white ";
    case CardDisplayType.Default:
    default:
      return " bg-white ";
  }
}