import { CardDisplayType, LinkCard } from "../common/Card";
import { ProfileIcon } from "../common/ProfileIcon";
import { useUserContext } from "../userprovider/UserProvider";


const Settings = () => {
  const { settings } = useUserContext();
  const { displayName, profileImageUrl } = settings;

  return (
    <>
      <span className="text-xs text-slate-600 text-center">
        Tap a setting to edit
      </span>
      <LinkCard to="/settings/displayname" displayType={CardDisplayType.Default}>
        <div className="flex flex-row">
          <span>
            Display Name:
          </span>
          <span className="grow text-right">
            {displayName}
          </span>
        </div>
      </LinkCard>
      <LinkCard to="/settings/profileimage" displayType={CardDisplayType.Default}>
        <div className="flex flex-row items-center">
          <span>
            Profile Image:
          </span>
          <span className="grow" />
          <span >
            {profileImageUrl && <ProfileIcon imageUrl={profileImageUrl} />}
          </span>
        </div>
      </LinkCard>
    </>
  );
}


export default Settings;
