import { useEffect, useState } from 'react';
import { IoTrash } from 'react-icons/io5';
import { Link } from "react-router-dom";
import { ListSummary } from '../../models';
import { useAPIContext } from '../apiprovider/APIProvider';
import { Button, LinkCard, RowActions } from '../common';
import { useUserContext } from '../userprovider/UserProvider';

const Home = () => {
  const { getLists, deleteList } = useAPIContext();
  const { user } = useUserContext();
  const [lists, setLists] = useState<ListSummary[]>([]);

  const deleteListHandler = async (id: string) => {
    await deleteList(id);
    const updatedLists = await getLists();
    setLists(updatedLists);
  }

  useEffect(() => {
    async function getListsAsync() {
      setLists(await getLists())
    }
    if (user) {
      getListsAsync()
    }
  }, [user, getLists]);

  return (
    <>
      <NewList />
      <Lists lists={lists} onDeleteList={deleteListHandler} />
    </>
  );
}

const NewList = () => {
  return (
    <Link to="/list/" className="h-24 p-2" >
      <Button className="w-full text-4xl">
        New List
      </Button>
    </Link >
  );
}

interface ListsProps {
  lists: ListSummary[];
  onDeleteList: (listId: string) => Promise<void>
}

const Lists = (props: ListsProps) => {
  const { lists, onDeleteList } = props;
  return (
    <div className="pt-4 pb-0 flex flex-col h-full gap-3">
      {lists.map(list => {
        const { id, name, itemCount, lastUpdated } = list;
        return (
          <ListItem
            key={id}
            id={id}
            name={name}
            itemCount={itemCount}
            lastUpdated={lastUpdated}
            onDeleteList={onDeleteList} />
        );
      })}
    </div>
  );
}

interface ListItemProps {
  id: string;
  name: string;
  itemCount: number;
  lastUpdated: Date;
  onDeleteList: (listId: string) => Promise<void>
}

const ListItem = (props: ListItemProps) => {
  const { id, name, itemCount, lastUpdated, onDeleteList } = props;
  return (
    <RowActions firstAction={
      <div
        onClick={() => onDeleteList(id)}
        className="bg-rose-600 shadow-md text-white h-full pl-6 flex flex-row items-center">
        <IoTrash className="text-2xl" />
      </div>
    }>
      <LinkCard to={`/list/${id}`}>
        <span className="text-2xl">{name}</span>
        <div className="flex flex-row items-center text-xs">
          <span>Last updated {lastUpdated.toLocaleDateString()}</span>
          <div className="grow" />
          <span>{itemCount} item{itemCount > 1 || itemCount === 0 ? "s" : ""}</span>
        </div>
      </LinkCard>
    </RowActions>
  );
}

export default Home;
