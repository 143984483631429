import { ReactNode, useRef } from "react";
import Interactable from "react-interactable";
import { Animated } from 'react-native-web';


interface RowActionsProps {
  children: any
  firstAction: ReactNode
  secondAction?: ReactNode
}

export const RowActions = (props: RowActionsProps) => {
  const { children, firstAction, secondAction } = props;

  const damping = .5;
  let snapPoints = [{ x: -76, tension: 600, damping: damping }, { x: 0, tension: 900, damping: damping }];
  const deltaX = useRef(new Animated.Value(0)).current;

  return (
    <Interactable.View
      snapPoints={snapPoints}
      horizontalOnly={true}
      boundaries={{ left: -160, right: 0 }}
      animatedValueX={deltaX}
    >

      {children}

      {secondAction &&
        <Animated.View style={
          [{
            position: 'absolute',
            top: 0,
            right: "-100vw",
            height: "100%",
            width: "100%",
            paddingLeft: "1.5rem"
          }, {
            transform: [{
              translateX: deltaX.interpolate({
                inputRange: [0, 40],
                outputRange: [0, 50]
              })
            }]
          }
          ]}>
          {secondAction}
        </Animated.View>
      }
      <div
        style={{
          position: 'absolute',
          top: 0,
          right: "-100vw",
          height: "100%",
          width: "100%",
        }}
      >
        {firstAction}
      </div>
    </Interactable.View>
  )
}

export default RowActions;