import { Link } from "react-router-dom";
import { Button } from "../common";

const FourOhFour = () => {
  return (
    <div>
      <header>
        Looks like this doesn't exist anymore. Maybe it was deleted?
      </header>
      <p>
        <Link to={`/`}>
          <Button className="w-full">Go home</Button>
        </Link>
      </p>
    </div >

  );
}


export default FourOhFour;
