
export enum DisplayType {
  Default,
  Primary,
  Danger
}

interface ButtonProps {
  children: any
  disabled: boolean | undefined
  onClick: () => void
  displayType: DisplayType
  type: 'submit' | 'reset' | 'button' | undefined
  className: string
  style: any
}

export const Button = (props: Partial<ButtonProps>) => {
  const { children, disabled = false, onClick, displayType = DisplayType.Default, className = "", type = "button", style = {} } = props;
  var baseClassName = "py-1 h-12 w-full h-full "
  baseClassName += " border shadow-md border-solid border-width-1";

  switch (displayType) {
    case DisplayType.Default:
      baseClassName += " bg-slate-50 border-sky-700 hover:border-sky-500 hover:shadow-2xl active:border-sky-500"
      if (disabled) {
        baseClassName += " text-slate-300 border-slate-300"
      }
      break;
    case DisplayType.Primary:
      baseClassName += " bg-sky-700 text-slate-50 border-sky-700 hover:border-sky-500 hover:shadow-2xl active:border-sky-500"
      if (disabled) {
        baseClassName += " bg-slate-300 border-slate-300"
      }
      break;
    case DisplayType.Danger:
      baseClassName += " bg-red-700 text-slate-50 border-red-700 hover:shadow-2xl"
      if (disabled) {
        baseClassName += " bg-red-300 border-slate-300"
      }
      break;
  }
  const classNameValue = `${baseClassName} ${className}`
  return (
    <button
      className={classNameValue}
      style={{
        minHeight: "3rem",
        ...style
      }}
      onClick={onClick}
      disabled={disabled}
      type={type}

    >
      {children}
    </button>
  )
}

export default Button;