import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { CreateItemRequest, List, ListType, UpdateItemRequest, UpdateListRequest } from '../../models';
import { useAlertContext } from "../alerts/AlertContextProvider";
import { useAPIContext } from "../apiprovider/APIProvider";
import { useUserContext } from "../userprovider/UserProvider";
import { List as ListComponent } from './List';

const ListPage = () => {
  let params = useParams();
  let [searchParams] = useSearchParams();

  const listId = params.listId;
  const inviteCode = searchParams.get("inviteCode");

  const navigate = useNavigate();
  const { loggedIn, user } = useUserContext();
  const { createAlert } = useAlertContext();

  const [list, setList] = useState<List | null>(null);
  const [authorized, setAuthorized] = useState(true);
  const { baseUrl, getList, updateList, deleteList, addItem, updateItem, deleteItem, addCollaborator } = useAPIContext();

  const loadList = useCallback(async () => {
    if (!listId) return;
    try {
      var tempList: List | null = null;
      if (inviteCode) {
        tempList = await addCollaborator(listId, inviteCode)
      }
      else {
        tempList = await getList(listId);
      }

      setList(tempList);
    }
    catch (error: any) {
      if (error.response.status === 403) {
        setAuthorized(false);
      }
      if (error.response.status === 404) {
        navigate("/404");
      }
    }
  }, [addCollaborator, getList, inviteCode, listId, navigate]);

  useEffect(() => {
    if (loggedIn && !list) {
      loadList();
    }
  }, [loadList, loggedIn, list]);

  const addItemHandler = async (item: CreateItemRequest) => {
    if (!listId) {
      return;
    }
    try {
      const list = await addItem(listId, item)
      setList(list);
    }
    catch (e) {
      console.error(e);
      createAlert({
        title: "Hmm",
        message: "Something went wrong, try again.",
        isError: true,
      });
      await loadList();
    }
  }

  const updateItemHandler = async (itemId: string, itemCurrentVersion: number, updateReq: UpdateItemRequest) => {
    if (!listId) {
      return;
    }
    try {
      updateReq.current_version = itemCurrentVersion;
      const list = await updateItem(listId, itemId, updateReq)
      setList(list);
    }
    catch (e: any) {
      if (e.response.status === 409) {
        createAlert({
          title: "Hmm",
          message: "Looks like someone updated this item at the same time as you. Try again.",
          isError: true,
        });
        await loadList();
      } else if (e.response.status === 404) {
        createAlert({
          title: "Hmm",
          message: "Looks like someone deleted this item.",
          isError: true,
        });
        await loadList();
      } else {
        console.error(e);
      }
    }
  }

  const claimItemHandler = async (itemId: string, itemVersion: number) => {
    if (!listId) {
      return;
    }
    try {
      const request = {
        claimed: true,
        link: undefined,
        description: undefined,
        current_version: itemVersion
      } as UpdateItemRequest
      const list = await updateItem(listId, itemId, request)
      setList(list);
    }
    catch (e: any) {
      if (e.response.status === 409) {
        createAlert({
          title: "Dang!",
          message: "Looks like someone claimed this item just before you did!",
          isError: false,
        });
        await loadList();
      } else {
        console.error(e);
        createAlert({
          title: "Hmm",
          message: "Something went wrong, try again.",
          isError: true,
        });
        await loadList();
      }
    }
  }

  const releaseItemHandler = async (itemId: string, itemVersion: number) => {
    if (!listId) {
      return;
    }
    try {
      const request = {
        claimed: false,
        link: undefined,
        description: undefined,
        current_version: itemVersion
      } as UpdateItemRequest;
      const list = await updateItem(listId, itemId, request)
      setList(list);
    }
    catch (e: any) {
      if (e.response.status === 409) {
        createAlert({
          title: "Hmm",
          message: "Looks like someone updated this item at the same time as you. Try again.",
          isError: false,
        });
        await loadList();
      } else {
        createAlert({
          title: "Hmm",
          message: "Something went wrong, try again.",
          isError: true,
        });
        await loadList();
        console.error(e);
      }
    }
  }

  const deleteItemHandler = async (itemId: string) => {
    if (!listId) {
      return;
    }
    try {
      const list = await deleteItem(listId, itemId)
      setList(list);
    }
    catch (e) {
      console.error(e);
      createAlert({
        title: "Hmm",
        message: "Something went wrong, try again.",
        isError: true,
      });
      await loadList();
    }
  }

  const updateListHandler = async (updateListReq: UpdateListRequest) => {
    if (!listId) {
      return;
    }
    try {
      const list = await updateList(listId, updateListReq)
      setList(list);
    }
    catch (e) {
      console.error(e);
      createAlert({
        title: "Hmm",
        message: "Something went wrong, try again.",
        isError: true,
      });
      await loadList();
    }
  }

  const deleteListHandler = async () => {
    if (!listId) {
      return;
    }
    try {
      await deleteList(listId)
      navigate("/");
    }
    catch (e) {
      console.error(e);
      createAlert({
        title: "Hmm",
        message: "Something went wrong, try again.",
        isError: true,
      });
      await loadList();
    }
  };

  var inviteLink = "";
  if (list) {
    inviteLink = `${baseUrl}/list/${list?.id}?inviteCode=${list?.inviteCode}`
  }

  const items = list?.items.map((item) => {
    var canEdit = false;
    if (list.type === ListType.Gift) {
      // for gift lists, only the owner can edit items
      canEdit = user?.uid === list.ownerId;
    } else {
      // for default lists you can only edit an item if it's not claimed or you claimed it.
      canEdit = !item.claimed || (item.claimed && item.claimedBy === user?.uid);
    }

    return {
      id: item.id,
      description: item.description,
      link: item.link,
      claimed: item.claimed,
      claimedByCurrentUser: (item.claimed && item.claimedBy === user?.uid) ?? false,
      claimedByName: item.claimedByName,
      claimedByProfileImageUrl: item.claimedByProfileImageUrl,
      canEdit: canEdit,
      createDate: item.createDate,
      updateDate: item.updateDate,
      version: item.version,
    };
  }) ?? [];

  // for gift lists, only owners can edit
  var canEditList = false;
  var showClaimedStatus = false;
  var canAddItems = true;
  if (list) {
    canEditList = list.ownerId === user?.uid;
    canAddItems = list.ownerId === user?.uid || list.type === ListType.Default;
    showClaimedStatus = list.ownerId === user?.uid && list.type === ListType.Gift ? false : true;
  }

  return (
    <>
      {authorized && listId && list && user && <ListComponent
        id={listId}
        canEdit={canEditList}
        canEditItems={canAddItems}
        canAddItems={canAddItems}
        name={list.name}
        createDate={list.createDate.toLocaleDateString()}
        inviteLink={inviteLink}
        isPublic={list.public}
        showClaimedStatus={showClaimedStatus}
        items={items}
        ownerId={list.ownerId}
        type={list.type}
        addItem={addItemHandler}
        updateItem={updateItemHandler}
        claimItem={claimItemHandler}
        releaseItem={releaseItemHandler}
        deleteItem={deleteItemHandler}
        updateList={updateListHandler}
        deleteList={deleteListHandler}
        createAlert={createAlert} />
      }
      {!authorized &&
        <p>You don't have access to view this list. You either need an invite link or the list creator needs to make the list public</p>
      }
    </>

  );
}

export default ListPage;
