import { ErrorMessage, Field } from 'formik';
import { ClipboardEvent } from 'react';

interface TextInputProps {
  name: string
  placeholder?: string
  className?: string
  autoFocus?: boolean
  error?: string
  enableErrorMessage?: boolean
  password?: boolean
  onPaste?: (e: ClipboardEvent<HTMLInputElement>) => void
  innerRef?: (instance: any) => void
}

export const TextInput = (props: TextInputProps) => {
  const {
    name,
    error,
    placeholder = "",
    className = "",
    autoFocus = false,
    enableErrorMessage = true,
    password = false,
    onPaste,
    innerRef = null,
  } = props;
  var baseClassName = "border shadow-md p-2 w-full h-12 focus:outline-none focus:shadow-lg focus:shadow-slate-7 "

  if (error) {
    baseClassName += " border-rose-600";
  }
  return (

    <div className="flex flex-col items-center">
      <Field
        type={password ? "password" : "text"}
        innerRef={innerRef}
        name={name}
        autoFocus={autoFocus}
        placeholder={placeholder}
        onPaste={onPaste}
        className={`${baseClassName} ${className}`}
      />
      {enableErrorMessage && <ErrorMessage name={name} component="div" />}
    </div>
  )
}

export default TextInput;