
interface ErrorMessageProps {
  value: string
}

export const ErrorMessage = (props: ErrorMessageProps) => {
  const { value } = props;
  return (
    <div className="flex flex-col items-center text-rose-600" >
      {value}
    </div>
  )
}

export default ErrorMessage;