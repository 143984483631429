
import { createContext, useCallback, useContext, useState } from 'react';

export function useAlertContext() {
  const context = useContext(AlertContext);
  if (context === undefined) {
    throw new Error("APIContext must be used inside an APIProvider")
  }

  return context
}

const AlertContext = createContext<IAlertContext | undefined>(undefined);

export interface Alert {
  title: string;
  message?: string;
  isError: boolean;
  link?: string;
}

export interface IAlertContext {
  createAlert: (alert: Alert) => void
  clearAlert: () => void
  alert: Alert | null
}

const AlertContextProvider = (props: { children: any; }) => {
  const { children } = props;

  const [alert, setAlert] = useState<Alert | null>(null)

  const createAlert = useCallback((alert: Alert) => {
    setAlert(alert);
  }, [])

  const clearAlert = useCallback(() => {
    setAlert(null);
  }, []);

  return (
    <AlertContext.Provider
      value={{
        createAlert: createAlert,
        clearAlert: clearAlert,
        alert: alert
      }}
    >
      <>
        {children}
      </>
    </AlertContext.Provider>
  )
};

export default AlertContextProvider;