import { FieldArray, Form, Formik } from 'formik';
import { ClipboardEvent, createRef, useCallback, useEffect, useState } from 'react';
import { IoTrash } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import { isHttpsUri, isHttpUri } from 'valid-url';
import { CreateListRequest, CreateListRequestItem, ListType } from '../../models';
import { useAPIContext } from '../apiprovider/APIProvider';
import { Button, TextInput, Toggle } from '../common';
import { DisplayType } from '../common/Button';

const CreateList = () => {
  const validate = (values) => {
    const errors = {} as any;
    if (!values.name) {
      errors.name = 'A name is required!';
    }

    values.items && values.items.forEach((item, index) => {
      const itemErrors = {} as any;
      if (!item.description) {
        itemErrors.description = "A description is required"
      }
      const linkTrimmed = item.link?.trim();
      if (linkTrimmed) {
        const linkTrimmed = item.link.trim();
        const validhttps = isHttpsUri(linkTrimmed);
        const validhttp = isHttpUri(linkTrimmed);
        if (!(validhttps || validhttp)) {
          itemErrors.link = "links must be a valid url (https://...)";
        }
      }
      // only add errors struct if needed
      if (itemErrors.description || itemErrors.link) {
        if (!errors.items) { errors.items = {} }
        errors.items[`${index}`] = itemErrors;
      }
    })
    return errors;
  };

  const { createList } = useAPIContext();
  const navigate = useNavigate();

  const submit = async (values, { setSubmitting }) => {
    try {
      const listItems = values.items?.map(item => {
        return {
          description: item.description.trim(),
          link: item.link?.trim()
        } as CreateListRequestItem
      });

      const newList = {
        name: values.name.trim(),
        public: values.public,
        type: values.isGiftList ? ListType.Gift : ListType.Default,
        items: listItems
      } as CreateListRequest;

      const list = await createList(newList);
      setSubmitting(false);
      navigate(`/list/${list.id}`);
    }
    catch (e) { console.error(e) }  // TODO: handle submit error;
  }

  const autoFocusFn = useCallback(element => (element ? element.focus() : null), []);
  const addAnotherItem = (arrayHelpers) => {
    arrayHelpers.push({ description: "", link: "" });
    setScrollTrigger(scrollTrigger + 1);
  }

  const lastItemRef = createRef<HTMLDivElement>();
  const scrollToBottom = useCallback(() => {
    lastItemRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [lastItemRef]);

  const [scrollTrigger, setScrollTrigger] = useState(0);
  useEffect(() => {
    if (scrollTrigger !== 0) {
      scrollToBottom();
    }

  }, [scrollTrigger, scrollToBottom]);

  const getPasteHandler = useCallback((fieldName: string, setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void) => {
    return (e: ClipboardEvent<HTMLInputElement>) => {
      if (e.clipboardData) {
        let value = e.clipboardData.getData('text/plain');
        const results = value.match(/(?:(?:https?:\/\/)|(?:www\.))[^\s]+/g);
        // update the value to the first result. If none, do nothing
        if (results && results.length > 0) {
          const target = e.target as HTMLInputElement;
          target.value = results[0];

          setFieldValue(fieldName, results[0], true);
          e.preventDefault();
        }
      }
    }
  }, []);

  return (
    <Formik
      initialValues={{ name: "", public: false, isGiftList: true, items: [] }}
      validate={validate}
      onSubmit={submit}
    >
      {({ values, isValid, isSubmitting, errors, setFieldValue }) => (
        <Form className="grow flex flex-col h-full px-2 ">
          <div className="w-full text-center text-2xl pt-6 pb-4">Create A New List</div>
          <TextInput
            name="name"
            error={errors?.name}
            placeholder="Enter a name for this list"
            enableErrorMessage={false}
            autoFocus
            className="text-2xl" />

          <div className="pt-4 flex flex-row items-center">
            <span className="text-lg">This is a wish list</span>
            <div className="grow" />
            <Toggle name="isGiftList" />
          </div>

          {/* <div className="flex flex-row items-center">
              <span className="text-lg">Make it public? </span>
              <IoInformationCircle className="mx-2" />
              <div className="grow" />
              <Toggle name="public" />
            </div> */}

          <FieldArray name="items">
            {(arrayHelpers) => {
              return (
                <div className="grow flex flex-col gap-2">
                  {values?.items?.map((item, index) => {
                    const itemsError = errors.items;
                    const itemErrors = !itemsError ? null : itemsError[index] as any;
                    return (
                      <div key={index} className="flex flex-row items-center border rounded p-2  bg-white">
                        <div className="grow flex flex-col gap-1">
                          <span>New item:</span>
                          <TextInput
                            innerRef={index === values.items.length - 1 ? autoFocusFn : null}
                            type="text"
                            error={itemErrors?.description}
                            name={`items[${index}].description`}
                            enableErrorMessage={false}
                            placeholder="Add a description..."
                          />

                          <TextInput
                            type="text"
                            name={`items[${index}].link`}
                            error={itemErrors?.link}
                            onPaste={getPasteHandler(`items[${index}].link`, setFieldValue)}
                            placeholder="https://amazon.com/... (optional)" />
                        </div>

                        <IoTrash
                          className="mx-3 text-2xl"
                          onClick={() => arrayHelpers.remove(index)} />
                      </div>
                    )
                  })}
                  <div className="grow" />
                  <Button
                    onClick={() => addAnotherItem(arrayHelpers)}
                    className="mb-2">
                    {/* show this when user has removed all items from the list */}
                    {values?.items?.length > 0 ? "Add another item" : "Add an item"}
                  </Button>
                </div>
              )
            }}
          </FieldArray>

          <Button
            displayType={DisplayType.Primary}
            type="submit"
            disabled={isSubmitting || !isValid}
            className="mb-1"
          >
            Create List
          </Button>
          <span
            className="text-sm text-slate-700 self-center">
            You can always add more stuff later
          </span>
          <div ref={lastItemRef} />

        </Form>
      )
      }
    </Formik >
  );
}

export default CreateList;
