import { Form, Formik } from "formik";
import { ClipboardEvent, useCallback } from "react";
import Button, { DisplayType } from "../common/Button";
import TextInput from "../common/TextInput";
import { ListItemView, validateItem } from "./Item";

export interface EditItemProps {
  item: ListItemView
  onSubmit: (values: any) => Promise<void>
  onCancel: () => void
}

export const EditItem = (props: EditItemProps) => {
  const { item, onSubmit, onCancel } = props;


  const getPasteHandler = useCallback((setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void) => {
    return (e: ClipboardEvent<HTMLInputElement>) => {
      if (e.clipboardData) {
        let value = e.clipboardData.getData('text/plain');
        const results = value.match(/(?:(?:https?:\/\/)|(?:www\.))[^\s]+/g);
        // update the value to the first result. If none, do nothing
        if (results && results.length > 0) {
          const target = e.target as HTMLInputElement;
          target.value = results[0];

          setFieldValue('link', results[0], true);
          e.preventDefault();
        }
      }
    }
  }, []);

  return (
    <Formik
      initialValues={{ description: item.description ?? "", link: item.link ?? "" }}
      validate={validateItem}
      onSubmit={onSubmit}
      className="w-full">
      {({ isValid, isSubmitting, errors, setFieldValue }) => (
        <Form>
          <TextInput
            name="description"
            placeholder="item description"
            error={errors.description}
            enableErrorMessage={false}
            className="mb-2" />
          <TextInput
            name="link"
            placeholder="https://amazon.com/... (optional)"
            error={errors.link}
            onPaste={getPasteHandler(setFieldValue)}
            className="mb-2" />
          <div className="w-full flex flex-row gap-x-1 justify-center">
            <Button className="grow max-w-xs" onClick={onCancel}>Cancel</Button>
            <Button displayType={DisplayType.Primary} type="submit" className="grow max-w-xs" disabled={isSubmitting || !isValid}>Update</Button>
          </div>
        </Form>)}
    </Formik>
  );
}